// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "admin-lte"
import jQuery from "jquery";
import daterangepicker from "daterangepicker";
import "bs-stepper"
import '@fortawesome/fontawesome-free/css/all';
import '../style/style';
import 'bootstrap'
import 'select2'
import Clipboard from 'clipboard'

window.$ = jQuery;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
  $(".close").on("click", function(e) {
    $(e.currentTarget).parent(".alert").hide();
  });

  jQuery('input.daterange').daterangepicker({
    locale: { format: 'YYYY-MM-DD' }
  });

  let date = new Date();
  date.setTime(Date.now() + 1 * 365 * 24 * 60 * 60 * 1000);
  jQuery('input.date').daterangepicker({
    startDate: date.toISOString(),
    autoUpdateInput: false,
    timePicker: false,
    singleDatePicker: true,
    locale: { format: 'YYYY-MM-DD', cancelLabel: 'Clear' }
  });
  jQuery('input.date').on('apply.daterangepicker', function(ev, picker) {
    jQuery(this).val(picker.startDate.format('YYYY-MM-DD'));
  });
  jQuery('input.date').on('cancel.daterangepicker', function(ev, picker) {
    jQuery(this).val('');
  });

  $('#confirmModal').on('shown.bs.modal', function () {
    $('#modalInput').trigger('focus')
  });
  jQuery("#select2").select2({
    tags: true,
    tokenSeparators: [',', ' ']
  });

  // After downloading the certificate,
  // prevent the button from being pressed to prevent certs re-creation.
  $("#certs_download_button").on("click", () => {
    $("#certs_download_button").hide()
    $("#certs_cancel_button").hide();
    $("#certs_back_button").show();
  });

  new Clipboard('.copy-btn');
});
